import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  NameAndTitle,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:mark@madwatt.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            {
              /*            
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Team")}</Language>
              <Para>
                Mark Thien
                <NameAndTitle>Software Development Executive</NameAndTitle>
              </Para>
              <Para>
                Gemi Eka
                <NameAndTitle>Principal Software Engineer</NameAndTitle>
                </Para>
              <Para>
                Trung Minh
                <NameAndTitle>Senior Software Engineer</NameAndTitle>
              </Para>
              {
                //
              <Para>
                Kenny Gan 
                <NameAndTitle>Senior Product Owner</NameAndTitle>
              </Para>
                //
              }
              <Para>
                Cuong Ng
                <NameAndTitle>Senior Software Engineer</NameAndTitle>
              </Para>
            </Col>              
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>7500A Beach Rd, </Para>
              <Para>#05-319 The Plaza,</Para>
              <Para>Singapore 199591</Para>
              <Para>Phone Singapore: <a href="https://wa.me/6591177135" target="_blank">+6591177135</a></Para>
              <Para>Phone Malaysia: <a href="https://wa.me/601151179832" target="_blank">+601151179832</a></Para>
            </Col>            

                <Col lg={8} md={8} sm={12} xs={12}>
                  <Title>{t("Policy")}</Title>
                  <Large to="/" left="true">
                    {t("Application Security")}
                  </Large>
                  <Large left="true" to="/">
                    {t("Software Principles")}
                  </Large>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Empty />
                  <Large left="true" to="/">
                    {t("Support Center")}
                  </Large>
                  <Large left="true" to="/">
                    {t("Customer Support")}
                  </Large>
                </Col>
              */
            }
          </Row>
          <Row justify="space-between">
          {
              /*            
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>7500A Beach Rd, </Para>
              <Para>#05-319 The Plaza,</Para>
              <Para>Singapore 199591</Para>
              <Para>Phone: +6591177135, +60172189003</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
            </Col>
              */
            }
            {
              /*
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>

              */
            }

          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="#"
                src="github.svg"
              />
              <SocialLink
                href="#"
                src="twitter.svg"
              />
              <SocialLink
                href="#"
                src="linkedin.svg"
              />
              <SocialLink
                href="#"
                src="medium.svg"
              />
              {
                /*
              <a href="https://www.buymeacoffee.com/adrinlol">
                <img
                  src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=adrinlol&button_colour=FF5F5F&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
                  alt="Buy me a pizza"
                />
              </a>                
                */
              }
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
